<div>
  <mat-paginator [length]="bankStatusOverrideAudits.totalRecords"
                 [pageIndex]="paginationRequest.pageIndex"
                 [pageSize]="paginationRequest.pageSize"
                 [pageSizeOptions]="[10, 20, 30, 40, 50]"
                 showFirstLastButtons
                 aria-label="Select page of Bank Status Override Audits"
                 (page)="onPageChange($event)">
  </mat-paginator>

  <div style="display: block; overflow-x: auto; white-space: nowrap; min-height: 10rem; min-height: 230px;">
    <table mat-table [dataSource]="bankStatusOverrideAudits.response" matSort (matSortChange)="onColumnSort($event)" class="mat-elevation-z0 w-100">
      <ng-container matColumnDef="PersonCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IB ID" style="min-width: 13rem">
          IB #
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator ID search icon" fontIcon="search" (click)="onShowIDSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showIDSearch = false">
            <table-search [placeholder]="'Enter IB ID'" [showSearch]="showIDSearch" (searchQueryChangeEvent)="onIDChange($event)" (showSearchEvent)="onShowIDSearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.personCode }}</td>
      </ng-container>

      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Educator First Name" style="min-width: 11rem">
          First name
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator first name search icon" fontIcon="search" (click)="onShowFirstNameSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showFirstNameSearch = false">
            <table-search [placeholder]="'Enter 3 letters of name'" [showSearch]="showFirstNameSearch" (searchQueryChangeEvent)="onFirstNameChange($event)" (showSearchEvent)="onShowFirstNameSearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Educator Last Name" style="min-width: 11rem">
          Last name
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator last name search icon" fontIcon="search" (click)="onShowLastNameSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showLastNameSearch = false">
            <table-search [placeholder]="'Enter 3 letters of name'" [showSearch]="showLastNameSearch" (searchQueryChangeEvent)="onLastNameChange($event)" (showSearchEvent)="onShowLastNameSearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email" style="min-width: 20rem">
          Email
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator email search icon" fontIcon="search" (click)="onShowEmailSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showEmailSearch = false">
            <table-search [placeholder]="'Enter 3 letters of email'" [showSearch]="showEmailSearch" (searchQueryChangeEvent)="onEmailChange($event)" (showSearchEvent)="onShowEmailSearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="SfLink">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SfLink" style="min-width: 9rem">SF Profile</th>
        <td mat-cell *matCellDef="let element"> <a href="{{ element.salesforceUrl }}" target="_blank">Link</a></td>
      </ng-container>

      <ng-container matColumnDef="UpdatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Updated At" style="min-width: 9rem">
          Date of change
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Updated by filter icon" fontIcon="calendar_today" (click)="onShowUpdatedAtFilter($event)"></mat-icon>
          <div style="position: absolute; width: 20rem;" (outsideClick)="showUpdatedAtFilter = false">
            <table-date-filter [showFilter]="showUpdatedAtFilter" (closeFilterEvent)="showUpdatedAtFilter" (filterChangedEvent)="onUpdatedAtFilterChange($event)"></table-date-filter>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.updatedAt | date }}</td>
      </ng-container>

      <ng-container matColumnDef="UpdatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Staff member" style="min-width: 16rem">
          Staff member
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Updated by name search icon" fontIcon="search" (click)="onShowUpdatedBySearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showUpdatedBySearch = false">
            <table-search [placeholder]="'Enter 3 letters of name'" [showSearch]="showUpdatedBySearch" (searchQueryChangeEvent)="onUpdatedByChange($event)" (showSearchEvent)="onShowUpdatedBySearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.updatedBy }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
    </table>
  </div>

  <mat-paginator [length]="bankStatusOverrideAudits.totalRecords"
                 [pageIndex]="paginationRequest.pageIndex"
                 [pageSize]="paginationRequest.pageSize"
                 [pageSizeOptions]="[10, 20, 30, 40, 50]"
                 showFirstLastButtons
                 aria-label="Select page of Bank Status Override Audits"
                 (page)="onPageChange($event)">
  </mat-paginator>
</div>
