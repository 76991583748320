<h1 mat-dialog-title>Confirmation</h1>
<hr />
<div mat-dialog-content>
  Validate this tax form?
</div>
<hr/>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close class="IBO__btn--secondary" (click)="onCancelClick()">Cancel</button>
  <button mat-button mat-dialog-close cdkFocusInitial class="IBO__btn--primary" (click)="onOkClick()">Validate now</button>
</div>
