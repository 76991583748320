import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { TaxVerificationsComponent, TaxVerificationDetailsDialog } from './verifications/tax-verifications/tax-verifications.component';
import { TaxVerificationComponent, TaxVerificationDocumentDialog } from './verifications/tax-verification/tax-verification.component';
import { BankVerificationsComponent, BankVerificationDetailsDialog } from './verifications/bank-verifications/bank-verifications.component';
import { BankVerificationComponent, BankVerificationDocumentDialog } from './verifications/bank-verification/bank-verification.component';
import { BankStatusOverridesComponent } from './bank-status-overrides/bank-status-overrides.component';
import { BankStatusOverrideAuditsComponent } from './bank-status-override-audits/bank-status-override-audits.component';
import { CallbackComponent } from './auth/callback/callback.component';

import { TableSearchComponent } from './shared/table-search/table-search.component';
import { TableFilterComponent } from './shared/table-filter/table-filter.component';
import { TableDateFilterComponent } from './shared/table-date-filter/table-date-filter.component';

import WebService from '../services/web.service';
import AuthService from '../services/auth.service';
import TokenService from '../services/token.service';
import OutsideClickDirective from '../directives/outside-click.directive';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DATE_LOCALE } from '@angular/material/core'

@NgModule({
  declarations: [
    AppComponent,
    OutsideClickDirective,
    NavMenuComponent,
    HomeComponent,
    TaxVerificationsComponent,
    TaxVerificationDetailsDialog,
    TaxVerificationComponent,
    TaxVerificationDocumentDialog,
    BankVerificationsComponent,
    BankVerificationDocumentDialog,
    BankVerificationComponent,
    BankVerificationDetailsDialog,
    BankStatusOverridesComponent,
    BankStatusOverrideAuditsComponent,
    TableSearchComponent,
    TableFilterComponent,
    TableDateFilterComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'tax-verifications', component: TaxVerificationsComponent, pathMatch: 'full' },
      { path: 'tax-verification/:id', component: TaxVerificationComponent },
      { path: 'bank-verifications', component: BankVerificationsComponent, pathMatch: 'full' },
      { path: 'bank-verification/:id', component: BankVerificationComponent, },
      { path: 'bank-status-overrides', component: BankStatusOverridesComponent, pathMatch: 'full' },
      { path: 'bank-status-override/:id', component: BankStatusOverrideAuditsComponent, },
      { path: 'oauth2/callback', component: CallbackComponent }
    ]),
    BrowserAnimationsModule
  ],
  providers: [WebService, AuthService, TokenService, MatDialog,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
