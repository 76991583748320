<h2>Review Tax Details</h2>
<hr />
<div class="row">
  <div class="col-md-8"><button class="IBO__btn--secondary" (click)="onNavigateToTaxVerifications()">&lt; Back to all approvals</button></div>
  <div class="col-md-4">
    <button class="IBO__btn--secondary" (click)="openRejectDialog()" *ngIf="showValidationButtons">Reject</button>
    <button class="IBO__btn" (click)="openApproveDialog()" *ngIf="showValidationButtons">Validate</button>
  </div>
</div>
<div class="row">
  <div class="col-md-2"><label>IB Person Code</label></div>
  <div class="col-md-8">{{verificationRequest.personCode}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Educator name</label></div>
  <div class="col-md-8">{{verificationRequest.firstName}} {{verificationRequest.lastName}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Review status</label></div>
  <div class="col-md-8">{{verificationRequest.statusDescription}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Review date created</label></div>
  <div class="col-md-8">{{verificationRequest.dateCreated | date}}</div>
</div>
<div class="row" *ngIf="!showValidationButtons">
  <div class="col-md-2"><label>Validation status</label></div>
  <div class="col-md-8">{{verificationRequest.validationStatusDescription}}</div>
</div>
<div class="row" *ngIf="showRejection">
  <div class="col-md-2"><label>Rejection reason</label></div>
  <div class="col-md-8">{{verificationRequest.rejectionReason}}</div>
</div>
<div class="row" *ngIf="showRejection">
  <div class="col-md-2"><label>Comments</label></div>
  <div class="col-md-8">{{verificationRequest.rejectionComments}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>SSN</label></div>
  <div class="col-md-8">{{verificationRequest.taxNumber}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Tax document link</label></div>
  <div class="col-md-8"><button class="IBO__btn--primary IBO_df-btn" (click)="onTaxDocumentDownload()">View tax document</button></div>
</div>
