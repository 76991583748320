export enum TaxReferenceType {
  SocialSecurityNumber = 1,
  NationalInsuranceNumber = 2,
  SingaporeTaxNumber = 3,
  NetherlandsTaxNumber = 4,
  CanadaTaxNumber = 5
}

export const TaxReferenceTypeDescriptions: Record<TaxReferenceType, string> = {
  [TaxReferenceType.SocialSecurityNumber]: 'Tax (US)',
  [TaxReferenceType.NationalInsuranceNumber]: 'Tax (UK)',
  [TaxReferenceType.SingaporeTaxNumber]: 'Tax (Singapore)',
  [TaxReferenceType.NetherlandsTaxNumber]: 'Tax (NL)',
  [TaxReferenceType.CanadaTaxNumber]: 'Tax (CA)',
};
