import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router"
import TokenService from '../../../services/token.service';
import WebService from '../../../services/web.service';

@Component({
  selector: 'callback',
  templateUrl: './callback.component.html',
})

export class CallbackComponent implements OnInit {
  constructor(private webService: WebService, private tokenService: TokenService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    let code = this.route.snapshot.queryParamMap.get('code');

    if (code) {
      this.getToken(code);
    } 
  }

  private async getToken(code: string) {
    let token = await this.webService.get<string>('/auth/GetAccessToken/' + code);
    this.tokenService.saveToken(token);
    this.router.navigate(['/'])
  }
}
