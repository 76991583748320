<h2>Bank Status Overrides</h2>
<hr />
  <mat-tab-group (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="SEARCH">
      <div style="overflow-x: hidden">
        <div class="row" style="margin-top: 20px;">
          <div class="col-md-1">IB#</div>
          <div class="col-md-2"><input class="form-control" (change)="onPersonCodeChange($event)" /></div>
          <div class="col-md-1">&nbsp;</div>
          <div class="col-md-1">Email</div>
          <div class="col-md-2"><input class="form-control" (change)="onEmailChange($event)" /></div>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col-md-1">First name</div>
          <div class="col-md-2"><input class="form-control" (change)="onFirstNameChange($event)" /></div>
          <div class="col-md-1">&nbsp;</div>
          <div class="col-md-1">Last name</div>
          <div class="col-md-2"><input class="form-control" (change)="onLastNameChange($event)" /></div>
          <div class="col-md-1"><button class="IBO__btn" style="height: 30px;" (click)="onSearchSubmit()">SUBMIT</button></div>
        </div>
        <div>
          <mat-paginator [length]="bankStatusOverrides.totalRecords"
                         [pageIndex]="paginationRequest.pageIndex"
                         [pageSize]="paginationRequest.pageSize"
                         [pageSizeOptions]="[10, 20, 30, 40, 50]"
                         showFirstLastButtons
                         aria-label="Select page of Bank Verifications"
                         (page)="onPageChange($event)">
          </mat-paginator>

          <div style="display: block; overflow-x: auto; white-space: nowrap; min-height: 10rem;">
            <table mat-table [dataSource]="bankStatusOverrides.response" matSort (matSortChange)="onColumnSort($event)" class="mat-elevation-z0 w-100">
              <ng-container matColumnDef="PersonCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IB ID" style="min-width: 13rem">IB #</th>
                <td mat-cell *matCellDef="let element">{{ element.personCode }}</td>
              </ng-container>

              <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Educator First Name" style="min-width: 11rem">First name</th>
                <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
              </ng-container>

              <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Educator Last Name" style="min-width: 11rem">Last name</th>
                <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
              </ng-container>

              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email" style="min-width: 20rem">Email</th>
                <td mat-cell *matCellDef="let element"> {{ element.email }}</td>
              </ng-container>

              <ng-container matColumnDef="StatusDescription">
                <th mat-header-cell *matHeaderCellDef style="min-width: 12rem">Bank account status</th>
                <td mat-cell *matCellDef="let element">{{ element.validationStateDescription }}</td>
              </ng-container>

              <ng-container matColumnDef="SfLink">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SfLink" style="min-width: 9rem">SF Profile</th>
                <td mat-cell *matCellDef="let element"> <a href="{{ element.salesforceUrl }}" target="_blank">Link</a></td>
              </ng-container>

              <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                  <button class="IBO__btn" (click)="openConfirmDialog(element.bankStatusOverrideId)">Change Status</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
            </table>
          </div>

          <mat-paginator [length]="bankStatusOverrides.totalRecords"
                         [pageIndex]="paginationRequest.pageIndex"
                         [pageSize]="paginationRequest.pageSize"
                         [pageSizeOptions]="[10, 20, 30, 40, 50]"
                         showFirstLastButtons
                         aria-label="Select page of Bank Verifications"
                         (page)="onPageChange($event)">
          </mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Previous Changes">
      <app-bank-status-override-audits></app-bank-status-override-audits>
    </mat-tab>
  </mat-tab-group>
