import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BankStatusOverride } from '../../models/BankStatusOverride';
import { BankStatusOverrideFeedback } from '../../models/BankStatusOverrideFeedback';
import { ValidationStatus, ValidationStatusDescriptions } from '../../models/enums/ValidationStatus';
import { BankStatusOverridePaginationRequest } from '../../models/pagination/BankStatusOverridePaginationRequest';
import { PaginationResponse } from '../../models/pagination/PaginationResponse';
import WebService from '../../services/web.service';
import { environment } from '../../environments/environment';
import { BankStatusOverrideAuditsComponent } from '../bank-status-override-audits/bank-status-override-audits.component';

export interface ConfirmationData {
  personCode: string;
  validationState: ValidationStatus;
}

@Component({
  selector: 'app-bank-status-overrides',
  templateUrl: './bank-status-overrides.component.html',
  styleUrls: ['./bank-status-overrides.component.scss']
})
export class BankStatusOverridesComponent implements OnInit, OnDestroy {
  @ViewChild(BankStatusOverrideAuditsComponent)
  private auditComponent!: BankStatusOverrideAuditsComponent;
  protected tableColumns: string[] = ['PersonCode', 'FirstName', 'LastName', 'Email', 'StatusDescription', 'SfLink', 'Actions'];
  protected bankStatusOverrides: PaginationResponse<BankStatusOverride>;
  protected paginationRequest: BankStatusOverridePaginationRequest;
  protected showFirstNameSearch: boolean;
  protected showLastNameSearch: boolean;
  protected showStatusFilter: boolean;
  protected statusEnumDescriptions: string[] = Object.values(ValidationStatusDescriptions);
  protected pushReload!: string;

  constructor(private webService: WebService, public dialog: MatDialog) {
    this.paginationRequest = {
      pageIndex: 0,
      pageSize: 10,
      sortOrder: null,
      firstName: null,
      lastName: null,
      email: null      
    } as BankStatusOverridePaginationRequest;

    this.bankStatusOverrides = {
      response: [],
      totalRecords: 0
    } as PaginationResponse<BankStatusOverride>;

    this.showFirstNameSearch = false;
    this.showLastNameSearch = false;
    this.showStatusFilter = false;
  }

  ngOnInit() {
    //do something here
  }

  ngOnDestroy(): void {  
  }
  async getData() {
    this.bankStatusOverrides = await this.webService.post<PaginationResponse<BankStatusOverride>>('/bankStatusOverride/', this.paginationRequest);
    this.bankStatusOverrides.response.forEach(x => {
      x.validationStateDescription = ValidationStatusDescriptions[x.validationState];
      x.salesforceUrl = environment.salesForceBaseUri + x.salesforceId;
    });
  }

  onTabChanged(event: MatTabChangeEvent) {
    if (event.index == 1) {
      this.auditComponent.getData();
    }
  }

  onPageChange(event: any) {
    this.paginationRequest.pageIndex = event.pageIndex;
    this.paginationRequest.pageSize = event.pageSize;
    this.getData();
  }

  onColumnSort(event: any) {
    this.paginationRequest.pageIndex = 0;

    if (event.direction) {
      this.paginationRequest.sortOrder = `${event.active}_${event.direction}`;
    }
    else {
      this.paginationRequest.sortOrder = null;
    }

    this.getData();
  }

  onPersonCodeChange(e: any) {
    this.paginationRequest.id = parseInt(e.target.value);
  }

  onFirstNameChange(e: any) {
    this.paginationRequest.firstName = e.target.value;
  }

  onEmailChange(e: any) {
    this.paginationRequest.email = e.target.value;
  }

  onLastNameChange(e: any) {
    this.paginationRequest.lastName = e.target.value;    
  }

  onSearchSubmit() {    
    this.getData();    
  }

 
  openConfirmDialog(id : number): void {
    const dialogRef = this.dialog.open(BankStatusOverridesDialog, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe((result : any) => {
      console.log('The confirm dialog was closed with: ', result);
      if (result) {
        var feedback: BankStatusOverrideFeedback =
        {
          status: ValidationStatus.DetailsRequired
        };

        this.webService.post('/bankStatusOverride/' + id + '/status', feedback).then(() => {
          this.getData();
        });                
      }
    });
  }
}


@Component({
  selector: 'bank-status-overrides-dialog',
  templateUrl: 'bank-status-overrides-dialog.html',
})
export class BankStatusOverridesDialog {
  constructor(
    public dialogRef: MatDialogRef<BankStatusOverridesDialog>
  ) {
  }
  onCancelClick(): void {
    this.dialogRef.close("");
  }

  onOkClick(): void {
    this.dialogRef.close("CHANGE");
  }  
}

