import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { BankStatusOverrideAudit } from '../../models/BankStatusOverrideAudit';
import { ValidationStatusDescriptions } from '../../models/enums/ValidationStatus';
import { BankStatusOverrideAuditsPaginationRequest } from '../../models/pagination/BankStatusOverrideAuditsPaginationRequest';
import { PaginationResponse } from '../../models/pagination/PaginationResponse';
import WebService from '../../services/web.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-bank-status-override-audits',
  templateUrl: './bank-status-override-audits.component.html',
  styleUrls: ['./bank-status-override-audits.component.scss']
})
export class BankStatusOverrideAuditsComponent implements OnInit, OnDestroy {
  protected tableColumns: string[] = ['PersonCode', 'FirstName', 'LastName', 'Email', 'SfLink', 'UpdatedAt', 'UpdatedBy' ];
  protected bankStatusOverrideAudits: PaginationResponse<BankStatusOverrideAudit>;
  protected paginationRequest: BankStatusOverrideAuditsPaginationRequest;
  protected showIDSearch: boolean;
  protected showFirstNameSearch: boolean;
  protected showLastNameSearch: boolean;
  protected showEmailSearch: boolean;
  protected showUpdatedAtFilter: boolean;
  protected showUpdatedBySearch: boolean;
  protected statusEnumDescriptions: string[] = Object.values(ValidationStatusDescriptions);

  constructor(private webService: WebService) {
    this.paginationRequest = {
      pageIndex: 0,
      pageSize: 10,
      sortOrder: null,
      firstName: null,
      lastName: null,
      email: null,
      dateCreatedEnd: null,
      dateCreatedStart: null,
      id: 0,
      updatedBy: null
    } as BankStatusOverrideAuditsPaginationRequest;

    this.bankStatusOverrideAudits = {
      response: [],
      totalRecords: 0
    } as PaginationResponse<BankStatusOverrideAudit>;

    this.showIDSearch = false;
    this.showFirstNameSearch = false;
    this.showLastNameSearch = false;
    this.showEmailSearch = false;
    this.showUpdatedAtFilter = false;
    this.showUpdatedBySearch = false;
  }

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy(): void {
  }
  async getData() {
    this.bankStatusOverrideAudits = await this.webService.post<PaginationResponse<BankStatusOverrideAudit>>('/bankStatusOverride/audits', this.paginationRequest);
    this.bankStatusOverrideAudits.response.forEach(x => {
      x.oldValidationStateDescription = ValidationStatusDescriptions[x.oldValidationState];
      x.newValidationStateDescription = ValidationStatusDescriptions[x.newValidationState];
      x.salesforceUrl = environment.salesForceBaseUri + x.salesforceId;
    });
  }
  
  onPageChange(event: any) {
    this.paginationRequest.pageIndex = event.pageIndex;
    this.paginationRequest.pageSize = event.pageSize;
    this.getData();
  }

  onColumnSort(event: any) {
    this.paginationRequest.pageIndex = 0;

    if (event.direction) {
      this.paginationRequest.sortOrder = `${event.active}_${event.direction}`;
    }
    else {
      this.paginationRequest.sortOrder = null;
    }

    this.getData();
  }

  onIDChange(query: string) {
    var parsed = +query;

    if (query.length > 0 && (isNaN(parsed) || parsed < 100)) {
      return;
    }

    this.paginationRequest.id = parsed;
    this.getData();
  }
  onShowIDSearch(event: any) {
    event.stopPropagation();
    this.showIDSearch = !this.showIDSearch;
  }

  onFirstNameChange(query: string) {
    this.paginationRequest.firstName = query;

    if (this.paginationRequest.firstName.length >= 3 || this.paginationRequest.firstName.length == 0) {
      this.getData();
    }
  }
  onShowFirstNameSearch(event: any) {
    event.stopPropagation();
    this.showFirstNameSearch = !this.showFirstNameSearch;
  }

  onLastNameChange(query: string) {
    this.paginationRequest.lastName = query;

    if (this.paginationRequest.lastName.length >= 3 || this.paginationRequest.lastName.length == 0) {
      this.getData();
    }
  }
  onShowLastNameSearch(event: any) {
    event.stopPropagation();
    this.showLastNameSearch = !this.showLastNameSearch;
  }

  onShowUpdatedAtFilter(event: any) {
    event.stopPropagation();
    this.showUpdatedAtFilter = !this.showUpdatedAtFilter;
  }
  onUpdatedAtFilterChange(event: any) {
    this.paginationRequest.dateCreatedStart = event.start;
    this.paginationRequest.dateCreatedEnd = event.end;
    this.getData();
  }

  onEmailChange(query: string) {
    this.paginationRequest.email = query;

    if (this.paginationRequest.email.length >= 5 || this.paginationRequest.email.length == 0) {
      this.getData();
    }
  }
  onShowEmailSearch(event: any) {
    event.stopPropagation();
    this.showEmailSearch = !this.showEmailSearch;
  }

  onUpdatedByChange(query: string) {
    this.paginationRequest.lastName = query;

    if (this.paginationRequest.lastName.length >= 3 || this.paginationRequest.lastName.length == 0) {
      this.getData();
    }
  }
  onShowUpdatedBySearch(event: any) {
    event.stopPropagation();
    this.showUpdatedBySearch = !this.showUpdatedBySearch;
  }
}
